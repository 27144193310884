import React, { useEffect } from 'react'
import { AdvCake } from '@features/advcakeModule'
import { IStateProps } from '@shared/components/head/model'
import { getConfigProperty, isProduction } from '@shared/pipes/config'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

import { useRegions } from '../../../regions/provider'
import { replaceDynamicVars } from '../../../regions/utils/replacer'

const DEFAULT_IMAGE_NAME = 'main.jpg'

const vkPixel = `
	!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?169",t.onload=function(){VK.Retargeting.Init("VK-RTRG-1214336-5XAGM"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
`

const investPixel = `
(function (d, w) {
	var n = d.getElementsByTagName("script")[0],
	s = d.createElement("script");
	s.type = "text/javascript";
	s.async = true;
	s.src = "https://qoopler.ru/index.php?ref="+d.referrer+"&page=" + encodeURIComponent(w.location.href);
	n.parentNode.insertBefore(s, n);
})(document, window);
`

export const Head = ({
	title,
	description,
	ogTitle,
	ogDescription,
	ogImageName,
	advCakeEvents,
	canonicalLink,
	pageWidth = 320, // Дефолтное значение и минимальный размер адаптивности
}: IStateProps) => {
	const { asPath } = useRouter()
	const regions = useRegions()
	const asPathArr = asPath.split('/')

	const ogUrl = `${getConfigProperty('HOST')}${asPath}`

	const ogReplacedData = replaceDynamicVars(regions.currentCity, {
		title,
		description,
		ogTitle,
		ogDescription,
		locality: '%city_im%',
	})

	useEffect(() => {
		advCakeEvents?.forEach((event) => AdvCake.pushEvent(event))
	}, [advCakeEvents])

	return (
		<NextHead>
			<title>{ogReplacedData.title}</title>
			<meta name='title' content={ogReplacedData.title} />
			<meta name='description' content={ogReplacedData.description} />
			<meta property='og:type' content='website' />
			<meta property='og:title' content={ogReplacedData.ogTitle || ogReplacedData.title} />
			{canonicalLink && <link rel='canonical' href={canonicalLink} />}
			<meta
				property='og:description'
				content={ogReplacedData.ogDescription || ogReplacedData.description}
			/>
			<meta property='og:image' content={`/og/images/${ogImageName || DEFAULT_IMAGE_NAME}`} />
			<meta property='og:url' content={ogUrl} />
			<meta property='og:site_name' content='Credit.Club' />
			<meta property='og:locale' content='ru' />
			<meta property='og:phone_number' content='8-800-775-80-09' />
			<meta property='og:country-name' content='Россия' />
			<meta property='og:locality' content={ogReplacedData.locality} />
			<meta
				name='viewport'
				content={`initial-scale=1.0, width=${pageWidth}px, maximum-scale=1.0`}
			/>
			<script
				async
				src='https://storage.yandexcloud.net/creditclub-frontend/static/marketing_data.js'
			/>
			{getConfigProperty('DISALLOW_PAGES')?.split(',')?.includes(asPath) && (
				<meta name='robots' content='noindex, follow' />
			)}
			{isProduction && (
				<>
					<script dangerouslySetInnerHTML={{ __html: vkPixel }} />
					<script
						type='text/javascript'
						id='advcakeAsync'
						dangerouslySetInnerHTML={{ __html: AdvCake.init() }}
					/>
				</>
			)}
			<meta name='yandex-verification' content='55f1edbd887913de' />
			<meta name='yandex-verification' content='e845c77b9e0ab561' />
			{asPathArr[asPathArr.length - 1] === 'invest' && (
				<script dangerouslySetInnerHTML={{ __html: investPixel }} />
			)}
		</NextHead>
	)
}
